import { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    console.log(open);
    setOpen(true);
  }

  return (
    <header
      style={{
        position: "absolute",
        zIndex: "100",
        top: "0",
        width: "100%",
      }}
      // className="relative"
      data-todo-x-data="Components.popover({ open: false, focus: true })"
      data-todo-x-init="init()"
      data-todo-at-keydown-escape="onEscape"
      data-todo-at-close-popover-group-window="onClosePopoverGroup"
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          width: "100%",
          backgroundColor: "#0E121B",
        }}
        className="bg-gray-900 pt-6 p-6"
      >
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <span className="sr-only">Broker-name</span>
                <img
                  className="h-8 w-auto sm:h-10"
                  src="/logo.svg"
                  alt="logo"
                />
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <button
                  type="button"
                  onClick={handleOpen}
                  // style={{ backgroundColor: "#161C2A" }}
                  className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white"
                  data-todo-at-click="toggle"
                  data-todo-at-mousedown="if (open) $event.preventDefault()"
                  aria-expanded="false"
                  data-todo-colon-aria-expanded="open.toString()"
                >
                  <span
                    style={{ backgroundColor: "#161C2A" }}
                    className="sr-only"
                  >
                    Open main menu
                  </span>
                  <svg
                    className="h-6 w-6"
                    data-todo-x-description="Heroicon name: outline/menu"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="hidden space-x-8 md:flex md:ml-10">
              <Link to="/about">
                <p className="text-base font-medium text-white hover:text-gray-300">
                  About us
                </p>
              </Link>

              {/* <a
                href="/"
                className="text-base font-medium text-white hover:text-gray-300"
              >
                Features
              </a> */}

              <Link to="/instruments">
                <p className="text-base font-medium text-white hover:text-gray-300">
                  Instruments
                </p>
              </Link>

              <Link to="/faq">
                <p className="text-base font-medium text-white hover:text-gray-300">
                  FAQs
                </p>
              </Link>
            </div>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-6">
            <a
              href="https://app.gatrades.live"
              className="text-base font-medium text-white hover:text-gray-300"
            >
              Log in
            </a>
            <a
              href="https://app.gatrades.live"
              className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
            >
              Get Started
            </a>
          </div>
        </nav>
      </div>

      <div
        data-todo-x-show="open"
        data-todo-x-transition-enter="duration-150 ease-out"
        data-todo-x-transition-enter-start="opacity-0 scale-95"
        data-todo-x-transition-enter-end="opacity-100 scale-100"
        data-todo-x-transition-leave="duration-100 ease-in"
        data-todo-x-transition-leave-start="opacity-100 scale-100"
        data-todo-x-transition-leave-end="opacity-0 scale-95"
        style={{ display: open ? "block" : "none" }}
        data-todo-x-description="Mobile menu, show/hide based on menu open state."
        className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
        data-todo-x-ref="panel"
        data-todo-at-click-away="open = false"
      >
        <div
          style={{ backgroundColor: "#161C2A", color: "white" }}
          className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div
            style={{ color: "white" }}
            className="px-5 pt-4 flex items-center justify-between"
          >
            <div>
              <img className="h-8 w-auto" src="/logo.svg" alt="" />
            </div>
            <div className="-mr-2">
              <button
                type="button"
                onClick={handleClose}
                style={{ outline: "none", border: "none" }}
                className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                data-todo-at-click="toggle"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  data-todo-x-description="Heroicon name: outline/x"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="pt-5 pb-6">
            <div className="px-2 space-y-1">
              <Link to="/about">
                <p
                  style={{ color: "white" }}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:font-bold"
                >
                  About us
                </p>
              </Link>

              <Link to="/faq">
                <p
                  style={{ color: "white" }}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:font-bold"
                >
                  FAQs
                </p>
              </Link>

              <Link to="/privacy">
                <p
                  style={{ color: "white" }}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:font-bold"
                >
                  Privacy Policy
                </p>
              </Link>
            </div>
            <div className="mt-6 px-5">
              <a
                href="https://app.gatrades.live"
                style={{ backgroundColor: "#1199FA" }}
                className="block text-center w-full py-3 px-4 rounded-md shadow bg-indigo-600 text-white font-medium hover:bg-indigo-700"
              >
                Create an account
              </a>
            </div>
            <div className="mt-6 px-5">
              <p
                style={{ color: "#c9c9c9" }}
                className="text-center text-base font-medium text-gray-500"
              >
                Already have an account?{" "}
                <a
                  style={{ color: "#1199FA" }}
                  href="https://app.gatrades.live"
                  className="text-gray-900 hover:underline"
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
