import Header from "./Header";

const Hero = () => (
  <>
    <div _style={{ maxHeight: "800px" }} className="overflow-y-auto">
      <div className="">
        <br />
        <br />
        <div className="relative overflow-hidden">
          <main cl>
            <div
              style={{ backgroundColor: "#0E121B" }}
              className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden"
            >
              <div className="mx-auto max-w-7xl lg:px-8 pb-8 ">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                    <div className="lg:py-24">
                      <a
                        style={{ backgroundColor: "#161C2A" }}
                        href="https://app.gatrades.live"
                        className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                      >
                        <span
                          style={{ backgroundColor: "#1199FA" }}
                          className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full"
                        >
                          NFTS
                        </span>
                        <span className="ml-4 text-sm">
                          Trade NFTs on our platform
                        </span>
                        <svg
                          className="ml-2 w-5 h-5 text-gray-500"
                          data-todo-x-description="Heroicon name: solid/chevron-right"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </a>
                      <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                        <span className="block">A new way to</span>
                        <span
                          className="block text-indigo-400"
                          style={{ color: "#1199FA" }}
                        >
                          Explore financial markets
                        </span>
                      </h1>
                      <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        Sign up today to trade and earn with over 50 asset
                        types. Including <strong>Forex, </strong>
                        <strong>Crypto </strong> and <strong>Stock </strong>
                        pairs. And NFTs!
                      </p>
                      <div className="mt-10 sm:mt-12">
                        <form
                          action="#"
                          className="sm:max-w-xl sm:mx-auto lg:mx-0"
                        >
                          <div className="sm:flex justify-center md:justify-center lg:block">
                            {/* <div className="mt-3 sm:mt-0 sm:ml-3"> */}
                            <button
                              type="submit"
                              style={{ backgroundColor: "#1199FA" }}
                              className="flex py-3 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                            >
                              <a href="https://app.gatrades.live">
                                Get Started
                              </a>
                            </button>
                            {/* </div> */}
                          </div>
                          {/* <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                            Start your free 14-day trial, no credit card
                            necessary. By providing your email, you agree to our{" "}
                            <a href="/" className="font-medium text-white">
                              terms or service
                            </a>
                            .
                          </p> */}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 -mb-16h-full sm:-mb-48 lg:m-0 lg:relative">
                    <div className="mx-auto h-full max-w-md px-4 sm:max-w-2xl lg:max-w-none lg:px-0">
                      <img
                        className="w-full sm:px-2 lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                        // src="https://tailwindui.com/img/component-images/cloud-illustration-indigo-400.svg"
                        src="/bg-5.png"
                        alt="hero banner"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* More main page content here... */}
          </main>
        </div>
      </div>
    </div>
  </>
);

export default Hero;
