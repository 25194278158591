import Hero from "./Hero";
import Footer from "./Footer";
import ThreeColumns from "./ThreeColumn";
import Panel from "./Panel";
import FeatureList from "./FeatureList";
import Subscriptions from "./Subscriptions";
import Header from "./Header";

function Main() {
  return (
    <div className="Main" style={{ maxWidth: "100vw", overflowX: "hidden" }}>
      <Header />
      <br />
      <br />
      <br />
      <Hero />
      {/* <ThreeColumns /> */}
      <Panel />
      <FeatureList />
      <Subscriptions />
      <hr style={{ border: "0.5px solid #161C2A" }} />
      <Footer />
    </div>
  );
}

export default Main;
