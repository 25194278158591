import React from "react";
import CenteredContent from "./CenteredContent";
import Footer from "./Footer";
import Header from "./Header";
import IntroHeader from "./IntroHeader";
import SplitNoImage from "./SplitNoImage";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <IntroHeader
        title="Privacy"
        desc="Learn about how we handle Privacy at Global Access Trades"
      />
      <SplitNoImage />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
